import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from "gatsby";

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="Page not found" robots={'noindex,nofollow'} />
    <h1>Page not found</h1>
    <Link to={'/'}>back</Link>
  </Layout>
)

export default NotFoundPage
